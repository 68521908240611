import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { DataTypePregunta } from '../../../interfaces/utils'
import { AllCardsType } from '../../../data/all-cards'
import { Zodiaco, ZodiacoType } from '../../../data/zodiaco'
import { copyResponse, getZodiac, isEmpty, selectFive, setMezcla } from '../../../utils/functions'
import { SaveAllCards, SetConsult } from '../../../store/actions'
import html2canvas from 'html2canvas'
import Swal from 'sweetalert2'
import { ButtonNormal } from '../../../components/buttons/button-normal'
import { ShowCards } from './show-cards'
import PhotoCards from './photo-cards'
import { interpretPropia } from './functions'

const Sino = () => {
    const dispatch: Dispatch<any> = useDispatch()
    const [data, setData] = useState<DataTypePregunta>({
        consultante: {
            name: '',
            date: '',
            zodiac: '',
        },
        consulta: '',
    })
    const [copia, setCopia]: any = useState(false)
    const { allCards, user, consult } = useSelector((state: any) => state)
    const [response, setResponse] = useState('')
    const [selectedCards, setSelectedCards] = useState<AllCardsType[]>([])
    const [showResponse, setShowResponse] = useState<boolean>(false)
    const [status, setStatus] = useState({
        isDone: false,
        mezcladas: false,
        repartidas: false,
        isConsulting: false,
        isInterpret: false,
    })
    const zodiaco: ZodiacoType[] = Zodiaco

    // SETEA LOS ESTADOS PARA REALIZAR UNA NUEVA CONSULTA CON EL MISMO CLIENTE
    const nuevaPregunta = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            ...data,
            consulta: '',
        })
        setShowResponse(false)
        setSelectedCards([])
    }

    // LIMPIA LOS ESTADOS PARA ATENDER UN NUEVO CLIENTE
    const nuevoCliente = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            consultante: {
                name: '',
                date: '',
                zodiac: '',
            },
            consulta: '',
        })
        setShowResponse(false)
        setSelectedCards([])
    }

    // MANEJA LOS ESTADOS
    const handleStatus = (name: string, value: boolean): void => {
        setStatus({
            ...status,
            [name]: value,
        })
    }

    // RECIBE UNA FECHA Y DEVUELVE EL SIGNO ZODIACAL
    const handleZodiacConsultant = (date: string): void => {
        let zodiac = getZodiac(date)
        setData({
            ...data,
            consultante: {
                ...data.consultante,
                zodiac,
                date,
            },
        })
    }

    // MANEJA LOS INPUTS DE LOS DATOS DEL CONSULTANTE
    const handleInputConsultant = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'date' && parseInt(event.target.value.slice(0, 4)) > 1900) {
            handleZodiacConsultant(event.target.value)
        } else {
            setData({
                ...data,
                consultante: {
                    ...data.consultante,
                    [event.target.name]: event.target.value,
                },
            })
        }
    }

    // MANEJA EL TEXTAREA DE LA CONSULTA
    const handleConsult = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setData({
            ...data,
            consulta: event.target.value,
        })
    }

    // MEZCLA LAS CARTAS
    const mezcla = () => {
        const mezcladas = setMezcla(allCards)
        dispatch(SaveAllCards(mezcladas))
        handleStatus('mezcladas', true)
    }

    // PREPARA LA INFORMACION Y DISPARA LA CONSULTA A LA IA
    const interpretar = () => {
        handleStatus('isConsulting', true)
        const info = interpretPropia(selectedCards, data, user.username, 'sino')
        dispatch(SetConsult(info))
    }

    useEffect(() => {
        if (status.mezcladas && !status.repartidas) {
            setSelectedCards(selectFive(allCards))
            handleStatus('repartidas', true)
        }
    }, [allCards, status])

    useEffect(() => {
        if (!isEmpty(consult)) {
            setStatus({
                ...status,
                isConsulting: false,
                isInterpret: true,
            })
            setResponse(consult.response)
            setShowResponse(true)
        }
    }, [consult])

    useEffect(() => {
        setStatus({
            isConsulting: false,
            isInterpret: false,
            isDone: false,
            repartidas: false,
            mezcladas: false,
        })
        setShowResponse(false)
        setResponse('')
        setSelectedCards([])
    }, [])

    // MUESTRA UNA DERSCRIPCION DEL SIGNO DEL ZODIACO DEL CONSULTANTE
    useEffect(() => {
        if (showResponse) {
            let signoConsultante = document.getElementById('consultante')
            if (signoConsultante && data.consultante.zodiac.length > 0) {
                let description = zodiaco.filter((signo) => signo.name === data.consultante.zodiac)
                signoConsultante.innerHTML = description[0].description
            }
        }
    }, [showResponse, data, zodiaco])

    // COPIA LA IMAGEN DE LAS CARTAS
    useEffect(() => {
        if (copia) {
            let element = document.getElementById('photo')
            if (element) {
                console.log('entre en element')
                html2canvas(element, { allowTaint: true, useCORS: true }).then((canvas: any) => {
                    console.log(canvas)
                    canvas.toBlob(async (blob: Blob) => {
                        try {
                            await navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })])
                            setCopia(false)
                            Swal.fire('Cartas copiadas', 'Imagen enviada al portapapeles', 'success')
                        } catch (error: any) {
                            setCopia(false)
                            Swal.fire('Error', error, 'error')
                        }
                    })
                })
            }
        }
    }, [copia])

    return (
        <div className="w-full mini:min-h-screen mini:h-full xl:h-height-main bg-sino bg-no-repeat bg-cover px-4 relative">
            <p className="mini:text-xl xl:text-4xl font-bold text-white italic w-full text-center py-5 drop-shadow-lg">
                Tirada del Si o No
            </p>
            <div className="flex mini:flex-col xl:flex-row w-full">
                {/* left */}
                <div className="mini:w-full xl:w-1/2 bg-violetLight h-full rounded-lg mini:py-5 mini:px-2 md:p-5 flex flex-col gap-6 shadow-card-horizontal">
                    <div className="flex mini:flex-col md:flex-row gap-3">
                        <input
                            name="name"
                            type="text"
                            value={data.consultante.name}
                            onChange={(e) => handleInputConsultant(e)}
                            placeholder="Nombre del Consultante"
                            className="h-10 mini:w-full md:w-1/2 pl-3 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            autoFocus={true}
                        />
                        <div className="flex gap-3">
                            <input
                                type="date"
                                name="date"
                                value={data.consultante.date}
                                onChange={(e) => handleInputConsultant(e)}
                                placeholder="Nacimiento"
                                className="pl-3 pr-1lh-10 w-1/2 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            />
                            <input
                                type="text"
                                name="zodiac"
                                value={data.consultante.zodiac}
                                readOnly={true}
                                className="px-3 h-10 rounded-lg text-base text-gray-400 w-1/2 disabled:bg-white"
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div>
                        <textarea
                            name="consult"
                            value={data.consulta}
                            className="p-3 h-[40vh] rounded-lg textr-base text-gray-400 w-full resize-none"
                            placeholder="Consulta"
                            onChange={(e) => handleConsult(e)}
                        />
                    </div>
                    <div className="flex gap-3 justify-center flex-wrap disabled:hidden">
                        <ButtonNormal
                            caption="Hecho"
                            onClick={() => handleStatus('isDone', true)}
                            disabled={
                                status.isDone ||
                                data.consultante.name.length === 0 ||
                                data.consultante.date.length === 0 ||
                                data.consulta.length === 0
                            }
                        />
                        <ButtonNormal
                            caption="Repartir"
                            onClick={() => mezcla()}
                            disabled={!status.isDone || status.repartidas}
                        />
                        <ButtonNormal
                            caption="Editar"
                            onClick={() => handleStatus('isDone', false)}
                            disabled={!status.isDone || status.repartidas}
                        />
                        <ButtonNormal
                            caption="Hacer foto de cartas"
                            onClick={() => setCopia(true)}
                            disabled={!status.repartidas || status.isConsulting}
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Interpretando' : 'Interpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.repartidas || status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Reinterpretando' : 'Reinterpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption="Ver Respuesta"
                            onClick={() => setShowResponse(true)}
                            disabled={response.length === 0}
                        />
                        <ButtonNormal
                            caption="Nueva Tirada"
                            onClick={() => nuevaPregunta()}
                            disabled={!status.isInterpret}
                        />
                        <ButtonNormal
                            caption="Nuevo Cliente"
                            onClick={() => nuevoCliente()}
                            disabled={!status.isInterpret}
                        />
                    </div>
                </div>
                {/* right */}
                {selectedCards.length > 0 ? (
                    <div className="mini:w-full xl:w-1/2 relative">
                        <ShowCards selectedCards={selectedCards} />
                    </div>
                ) : null}
                {selectedCards.length > 0 ? (
                    <div
                        className={`absolute w-fit h-fit top-[16vh] mini:left-[2vw] xl:left-[20vw] bg-tapiz ${
                            copia ? 'block' : 'hidden'
                        }`}
                        id="photo"
                    >
                        <PhotoCards cards={selectedCards} />
                    </div>
                ) : null}
                {showResponse ? (
                    <div className="absolute w-[90vw] h-[90vh] top-[2%] left-[5%] bg-zinc-200 shadow-2xl flex flex-row gap-5 rounded-xl mini:p-2 md:p-5">
                        <div className="mini:w-full xl:w-2/3 flex flex-col gap-3">
                            <textarea
                                className="w-full h-full resize-none rounded-lg mini:p-2 md:p-5"
                                value={response}
                                onChange={(e) => setResponse(e.target.value)}
                            />
                            <div className="flex flex-row flex-wrap justify-center gap-1">
                                <ButtonNormal caption="Volver" onClick={() => setShowResponse(false)} />
                                <ButtonNormal caption="Copiar Respuesta" onClick={() => copyResponse(response)} />
                                <ButtonNormal caption="Nueva Pregunta" onClick={() => nuevaPregunta()} />
                                <ButtonNormal caption="Nuevo Cliente" onClick={() => nuevoCliente()} />
                            </div>
                        </div>
                        <div className="w-1/3 flex flex-col gap-3 mini:hidden xl:block">
                            <p className="font-semibold">Consultante: {data.consultante.zodiac}</p>
                            <div
                                className="w-full bg-zinc-50 p-5 rounded-lg text-sm overflow-y-auto"
                                id="consultante"
                            ></div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default Sino
