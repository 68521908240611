import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Homepage from './pages/homepage/homepage'
import './App.css'
import Navbar from './components/navbar/navbar'
import ManualTres from './pages/consultas/manual-tres/manual-tres'
//import AutoTres from './pages/consultas/auto-tres/auto-tres'
import Login from './pages/login/login'
import MyConsultas from './pages/usuarios/my-consults/my-consults'
import AllConsults from './pages/usuarios/all-consults/all-consults'
//import General from './pages/consultas/general/general'
//import Amor from './pages/consultas/amor/amor'
import ConsultsView from './pages/usuarios/consults-view/consults-view'
//import YesOrNo from './pages/consultas/yesorno/yesorno'
import ComoFunciona from './pages/como-funciona/como-funciona'
import About from './pages/about/about'
//import Footer from './components/footer/footer'
import Celta from './pages/consultas/celta/celta'
import ManualYesOrNo from './pages/consultas/manual/yesorno/yesorno'
import Pregunta from './pages/consultas/pregunta/pregunta'
import Sino from './pages/consultas/sino/sino'
import Love from './pages/consultas/love/love'
import NewGeneral from './pages/consultas/gral/newGeneral'
import DosPreguntas from './pages/consultas/dos-preguntas/dos-preguntas'
import TresPreguntas from './pages/consultas/tres-preguntas/tres-preguntas'
import IchingPregunta from './pages/iching/pregunta/ichin-pregunta'
import Compatibilidad from './pages/consultas/compatibilidad/compatibilidad'
import Free from './pages/consultas/free/free'
import VidaPasada from './pages/consultas/vida-pasada/vida-pasada'

function App() {
    return (
        <div className="body-root">
            <Navbar />
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/manual/three" element={<ManualTres />} />
                <Route path="/auto/pregunta" element={<Pregunta />} />
                <Route path="/login" element={<Login />} />
                <Route path="/user/myconsults" element={<MyConsultas />} />
                <Route path="/user/allconsults" element={<AllConsults />} />
                <Route path="/general" element={<NewGeneral />} />
                <Route path="/auto/love" element={<Love />} />
                <Route path="/auto/celta" element={<Celta />} />
                <Route path="/user/consults-view/:id" element={<ConsultsView />} />
                <Route path="/auto/sino" element={<Sino />} />
                <Route path="/how-it-works" element={<ComoFunciona />} />
                <Route path="/about" element={<About />} />
                <Route path="/manual/yon" element={<ManualYesOrNo />} />
                <Route path="/auto/2-preguntas" element={<DosPreguntas />} />
                <Route path="/auto/3-preguntas" element={<TresPreguntas />} />
                <Route path="/iching/pregunta" element={<IchingPregunta />} />
                <Route path="/auto/compatibilidad" element={<Compatibilidad />} />
                <Route path="/auto/free" element={<Free />} />
                <Route path="/auto/vidapasada" element={<VidaPasada />} />
            </Routes>
        </div>
    )
}

export default App
