import React from 'react'
import { AllCardsType } from '../../../data/all-cards'
import { randomNumberInRange } from '../../../utils/functions'

const PhotoCards = ({ cards }: { cards: AllCardsType[] }) => {
    return (
        <div className="py-10 px-[40px] flex flex-wrap justify-center items-center gap-5 w-full after:bg-white-gradient after:absolute after:w-full after:h-full after:z-20 after:top-0 after-left-0">
            {cards.map((card: AllCardsType, index: number) => {
                return (
                    <img
                        key={index}
                        src={require('../../../assets/cards/' + card.url + '/' + card.image)}
                        className={
                            card.url === 'mayores'
                                ? 'w-[9.76vw] h-[18.12vw] mini:rounded-sm xl:rounded-[7px]'
                                : 'bg-white w-[9.76vw] h-[18.12vw] mini:rounded-sm xl:rounded-[7px]'
                        }
                        style={{
                            transform:
                                'rotate(' +
                                randomNumberInRange(-5, 5) +
                                'deg) translateY(' +
                                randomNumberInRange(-20, 20) +
                                'px',
                        }}
                        alt=""
                    />
                )
            })}
        </div>
    )
}

export default PhotoCards
