import React from 'react'
import { AllCardsType } from '../../../data/all-cards'

export const ShowCards = ({ selectedCards }: { selectedCards: AllCardsType[] }) => {
    return (
        <>
            <div className="flex flex-row mini:justify-center md:justify-between mini:items-start xl:items-center gap-2 px-[5%] h-full mini:flex-wrap md:flex-nowrap mini:py-10 xl:pt-0">
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[0].url + '/' + selectedCards[0].image)}
                        className="w-auto h-[26vh] rounded-md"
                        alt=""
                    />
                    <div className="w-auto bg-violetLight text-center rounded-md">{selectedCards[0].name}</div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[1].url + '/' + selectedCards[1].image)}
                        className="w-auto h-[26vh] rounded-md"
                        alt=""
                    />
                    <div className="w-auto bg-violetLight text-center rounded-md">{selectedCards[1].name}</div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[2].url + '/' + selectedCards[2].image)}
                        className="w-auto h-[26vh] rounded-md"
                        alt=""
                    />
                    <div className="w-auto bg-violetLight text-center rounded-md">{selectedCards[2].name}</div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[3].url + '/' + selectedCards[3].image)}
                        className="w-auto h-[26vh] rounded-md"
                        alt=""
                    />
                    <div className="w-auto bg-violetLight text-center rounded-md">{selectedCards[3].name}</div>
                </div>
                <div className="flex flex-col gap-2">
                    <img
                        src={require('../../../assets/cards/' + selectedCards[4].url + '/' + selectedCards[4].image)}
                        className="w-auto h-[26vh] rounded-md"
                        alt=""
                    />
                    <div className="w-auto bg-violetLight text-center rounded-md">{selectedCards[4].name}</div>
                </div>
            </div>
        </>
    )
}
