import { AllCardsType } from '../../../data/all-cards'
import { DataType2Preguntas } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataType2Preguntas, user: string, type: string) => {
    let consult = `Interpreta las dos tiradas de cartas que realizaste para ${data.consultante.name} que necesita saber en la primer tirada: ${data.consulta1}, en esta tirada salieron las siguientes cartas: ${cards[0].name}, ${cards[1].name}, ${cards[2].name}, ${cards[3].name} y ${cards[4].name}; y para la segunda tirada la consulta es: ${data.consulta2} y las cartas que salieron son: ${cards[5].name}, ${cards[6].name}, ${cards[7].name}, ${cards[8].name} y ${cards[9].name}. Decide tú como tarotista experta que eres, cual es el mejor tipo de lectura que puedes hacer para estas dos tiradas de cartas en base a lo que el consultante necesita saber en cada una de ellas. Puedes realizar combinaciones de cartas y tener en cuenta las influencias de las cartas, unas sobre otras. Si puedes ten en cuenta el signo zodiacal del consultante: ${data.consultante.zodiac}, sino, ni lo menciones. Trata de ser lo más detallada posible y que las respuestas sean extensas, pero que en conjunto no sobrepasen el límite de 300 tokens por favor. No hagas una enumeración de las cartas, sino un desarrollo de cada una de las lecturas, que sea fácil de leer y que responda concretamente lo que el consultante necesita saber. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación de cada una de las preguntas, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante en cada pregunta.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: 'Consulta uno: ' + data.consulta1 + '. Consulta dos:  ' + data.consulta2,
        cards: cards,
        type: type,
    }

    return info
}
