import React from 'react'
import { AllCardsType } from '../../../data/all-cards'

export const ShowCards = ({ selectedCards }: { selectedCards: AllCardsType[] }) => {
    return (
        <div className="flex flex-col h-full justify-center w-full mini:px-2 md:px-5 items-cente mini:pt-5 xl:pt-0">
            <div className="grid mini:grid-cols-5 xl:grid-cols-9 gap-3">
                {selectedCards.map((card: AllCardsType, index: number) => (
                    <div className="flex flex-col gap-2">
                        <img
                            src={require('../../../assets/cards/' + card.url + '/' + card.image)}
                            className="mini:w-[17vw] xl:w-[4.0vw] mini:h-auto xl:h-[15vh] rounded-md"
                            alt=""
                        />
                        <div className="mini:w-[17vw] xl:w-[4.0vw] bg-violetLight text-sm text-center rounded-md">
                            {card.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
