import React from 'react'
import { AllCardsType } from '../../../data/all-cards'
import { randomNumberInRange } from '../../../utils/functions'

export const PhotoCards = ({ cards }: { cards: AllCardsType[] }) => {
    return (
        <div className="relative w-full h-full bg-tapiz bg-no-repeat bg-cover after:bg-white-gradient after:absolute after:w-full after:h-full after:z-20 after:top-0 after-left-0">
            <img
                src={require('../../../assets/cards/' + cards[0].url + '/' + cards[0].image)}
                alt=""
                className="absolute top-[35%] left-[25%] mini:w-[14vw] md:w-[11vw] xl:w-[7vw] mini:h-[13vh] xl:h-[23vh] shadow-card-horizontal mini:rounded-sm xl:rounded-md"
                style={{
                    transform:
                        'rotate(' + randomNumberInRange(-5, 5) + 'deg) translateY(' + randomNumberInRange(-5, 5) + 'px',
                }}
            />
            <img
                src={require('../../../assets/cards/' + cards[1].url + '/' + cards[1].image)}
                alt=""
                className="absolute top-[35%] left-[25%] mini:w-[14vw] md:w-[11vw] xl:w-[7vw] mini:h-[13vh] xl:h-[23vh] -rotate-90 shadow-card-horizontal mini:rounded-sm xl:rounded-md"
                style={{
                    transform:
                        'rotate(' +
                        (randomNumberInRange(-5, 5) - 90) +
                        'deg) translateY(' +
                        randomNumberInRange(-5, 5) +
                        'px',
                }}
            />
            <img
                src={require('../../../assets/cards/' + cards[2].url + '/' + cards[2].image)}
                alt=""
                className="absolute top-[67%] left-[25%] mini:w-[14vw] md:w-[11vw] xl:w-[7vw] mini:h-[13vh] xl:h-[23vh] shadow-card-horizontal mini:rounded-sm xl:rounded-md"
                style={{
                    transform:
                        'rotate(' + randomNumberInRange(-5, 5) + 'deg) translateY(' + randomNumberInRange(-5, 5) + 'px',
                }}
            />
            <img
                src={require('../../../assets/cards/' + cards[3].url + '/' + cards[3].image)}
                alt=""
                className="absolute top-[35%] left-[5%] mini:w-[14vw] md:w-[11vw] xl:w-[7vw] mini:h-[13vh] xl:h-[23vh] shadow-card-horizontal mini:rounded-sm xl:rounded-md"
                style={{
                    transform:
                        'rotate(' + randomNumberInRange(-5, 5) + 'deg) translateY(' + randomNumberInRange(-5, 5) + 'px',
                }}
            />
            <img
                src={require('../../../assets/cards/' + cards[4].url + '/' + cards[4].image)}
                alt=""
                className="absolute top-[3%] left-[25%] mini:w-[14vw] md:w-[11vw] xl:w-[7vw] mini:h-[13vh] xl:h-[23vh] shadow-card-horizontal mini:rounded-sm xl:rounded-md"
                style={{
                    transform:
                        'rotate(' + randomNumberInRange(-5, 5) + 'deg) translateY(' + randomNumberInRange(-5, 5) + 'px',
                }}
            />
            <img
                src={require('../../../assets/cards/' + cards[5].url + '/' + cards[5].image)}
                alt=""
                className="absolute top-[35%] left-[45%] mini:w-[14vw] md:w-[11vw] xl:w-[7vw] mini:h-[13vh] xl:h-[23vh] shadow-card-horizontal mini:rounded-sm xl:rounded-md"
                style={{
                    transform:
                        'rotate(' + randomNumberInRange(-5, 5) + 'deg) translateY(' + randomNumberInRange(-5, 5) + 'px',
                }}
            />
            <img
                src={require('../../../assets/cards/' + cards[6].url + '/' + cards[6].image)}
                alt=""
                className="absolute top-[67%] left-[67%] mini:w-[14vw] md:w-[11vw] xl:w-[7vw] mini:h-[13vh] xl:h-[23vh] shadow-card-horizontal mini:rounded-sm xl:rounded-md"
                style={{
                    transform:
                        'rotate(' + randomNumberInRange(-5, 5) + 'deg) translateY(' + randomNumberInRange(-5, 5) + 'px',
                }}
            />
            <img
                src={require('../../../assets/cards/' + cards[7].url + '/' + cards[7].image)}
                alt=""
                className="absolute top-[35%] left-[67%] mini:w-[14vw] md:w-[11vw] xl:w-[7vw] mini:h-[13vh] xl:h-[23vh] shadow-card-horizontal mini:rounded-sm xl:rounded-md"
                style={{
                    transform:
                        'rotate(' + randomNumberInRange(-5, 5) + 'deg) translateY(' + randomNumberInRange(-5, 5) + 'px',
                }}
            />
            <img
                src={require('../../../assets/cards/' + cards[8].url + '/' + cards[8].image)}
                alt=""
                className="absolute top-[3%] left-[67%] mini:w-[14vw] md:w-[11vw] xl:w-[7vw] mini:h-[13vh] xl:h-[23vh] shadow-card-horizontal mini:rounded-sm xl:rounded-md"
                style={{
                    transform:
                        'rotate(' + randomNumberInRange(-5, 5) + 'deg) translateY(' + randomNumberInRange(-5, 5) + 'px',
                }}
            />
            <img
                src={require('../../../assets/cards/' + cards[9].url + '/' + cards[9].image)}
                alt=""
                className="absolute top-[35%] left-[84%] mini:w-[14vw] md:w-[11vw] xl:w-[7vw] mini:h-[13vh] xl:h-[23vh] shadow-card-horizontal mini:rounded-sm xl:rounded-md"
                style={{
                    transform:
                        'rotate(' + randomNumberInRange(-5, 5) + 'deg) translateY(' + randomNumberInRange(-5, 5) + 'px',
                }}
            />
        </div>
    )
}
