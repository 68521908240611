import { AllCardsType } from '../../../data/all-cards'
import { clasificacion } from '../../../data/clasification'
import { DataTypePregunta } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypePregunta, user: string, type: string) => {
    //console.log('Recibido en interpret:', data)
    let consult
    if (data.tipo === '2') {
        consult = `Según el significado de las cartas, me puedes proporcionar solo un resumen de la interpretación de la tirada de Tarot en un lenguaje sencillo, como explicándole a un niño, para ${data.consultante.name} que necesita saber sobre lo siguiente: ${data.consulta}, con las siguientes cartas: ${cards[0].name}, ${cards[1].name}, ${cards[2].name}, ${cards[3].name} y ${cards[4].name}. Por favor ten en cuenta la influencia sobre la consulta del signo zodiacal del consultante: ${data.consultante.zodiac}. Puedes usar la carta central o tercera como la carta que representa el asunto, las 2 primeras como pasado o influencia, según el caso, si puedes leer la combinación de estas 2 cartas mejor y las 2 últimas como futuro o resultado, en este caso también si puedes interpreta la combinación de ambas. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante.`
    } else if (data.tipo === '3') {
        consult = `Interpreta la tirada de cartas que realizaste para ${data.consultante.name} que necesita saber lo siguiente: ${data.consulta}, con las siguiente cartas: ${cards[0].name} en la situación actual o influencia principal, ${cards[1].name} en los desafíos u obstáculos, ${cards[2].name} en el consejo o guía, ${cards[3].name} en los factores externos o influencias adicionales y ${cards[4].name} en el resultado final o posible futuro. Si puedes ten en cuenta el signo zodiacal del consultante: ${data.consultante.zodiac}, sino, ni lo menciones. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante.`
    } else if (data.tipo === '4') {
        consult = `Interpreta la tirada de cartas que realizaste para ${data.consultante.name} que necesita saber lo siguiente: ${data.consulta}, con las siguientes cartas: ${cards[0].name}, ${cards[1].name}, ${cards[2].name}, ${cards[3].name} y ${cards[4].name}. Decide tú como tarotista experta que eres, cual es el mejor tipo de lectura que puedes hacer con estas cinco cartas en base a lo que el consultante necesita saber. Puedes realizar combinaciones de cartas y ten en cuenta las influencias de las cartas sobre las otras. Si puedes ten en cuenta el signo zodiacal del consultante: ${data.consultante.zodiac}, sino, ni lo menciones. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante.`
    } else {
        consult = `Respuesta a la consulta de ${data.consultante.name} de ${data.consultante.zodiac} sobre: ${
            data.consulta
        }:\n\n${cards[0].name}: ${clasificacion[cards[0].id] === 1 ? 'Positiva' : 'Negativa'}\n${cards[1].name}: ${
            clasificacion[cards[1].id] === 1 ? 'Positiva' : 'Negativa'
        }\n${cards[2].name}: ${clasificacion[cards[2].id] === 1 ? 'Positiva' : 'Negativa'}\n${cards[3].name}: ${
            clasificacion[cards[3].id] === 1 ? 'Positiva' : 'Negativa'
        }\n${cards[4].name}: ${clasificacion[cards[4].id] === 1 ? 'Positiva' : 'Negativa'}\n\nResultado: ${
            clasificacion[cards[0].id] +
                clasificacion[cards[1].id] +
                clasificacion[cards[2].id] +
                clasificacion[cards[3].id] +
                clasificacion[cards[4].id] >
            0
                ? 'Positivo'
                : 'Negativo'
        }\n\nLa clasificacion de las cartas arroja un resultado ${
            clasificacion[cards[0].id] +
                clasificacion[cards[1].id] +
                clasificacion[cards[2].id] +
                clasificacion[cards[3].id] +
                clasificacion[cards[4].id] >
            0
                ? 'Positivo para la consulta realizada, por lo tanto la respuesta es un SI.'
                : 'Negativo para la consulta realizada, por lo tanto la respuesta es un NO.'
        }\n\nEspero que esta tirada de tarot ayude a aclarar tus dudas y recuerda que el tarot es una herramienta que brinda una guia y orientación en cuestiones de la vida, pero las decisiones que tomes y los sucesos en tu vida dependen de tus acciones.\n\nTe deseo la mejor de las suertes...`
    }

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
