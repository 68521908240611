import { AllCardsType } from '../../../data/all-cards'
import { DataTypePregunta } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypePregunta, user: string, type: string) => {
    let consult = `Por favor, realiza una la interpretacion de la Tirada General de Tarot en un lenguaje sencillo de entender, pero hazla lo mas detallada y extensa posible y que no sobrepase los 300 tokens, para ${
        data.consultante.name
    } de signo zodiacal ${
        data.consultante.zodiac
    }, ten en cuenta es esta tirada su signo zodiacal y su personalidad número ${
        data.consultante.personality
    } según la numerología, ${
        data.consulta.length > 0
            ? ' e incluye en la interpretación el asunto que el consultante quiere recalcar, brindándole una respuesta concreta y definitiva al asunto: ' +
              data.consulta
            : ''
    } y organiza la tirada de la siguiente forma: En el amor las cartas ${cards[0].name} en la situación actual, ${
        cards[1].name
    } en las influencias, ${cards[2].name}  en los desafíos y/o obstáculos, ${cards[3].name} en el consejo y ${
        cards[4].name
    } en el resultado fina; en el trabajo:, ${cards[5].name} en la situación actual, ${
        cards[6].name
    } en las influencias ${cards[7].name} en los defasíos y/o obstáculos, ${cards[8].name} en el consejo y ${
        cards[9].name
    } en el resultado final; en el dinero: ${cards[10].name} en la situación actual, ${
        cards[11].name
    } en las influencias, ${cards[12].name} en los desafíos y/o obstáculos, ${cards[13].name} en el consejo y ${
        cards[14].name
    } en el resultado final; y por ultimo la salud con ${cards[15].name} en la situacion actual, ${
        cards[16].name
    } en las influencias, ${cards[17].name} en los desafíos y/o obstáculos, ${cards[18].name} en el consejo y ${
        cards[19].name
    } en el resultado final. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación de cada una de las aspectos desarrollados, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante en cada aspecto.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
