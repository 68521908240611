import { AllCardsType } from '../../../data/all-cards'
import { DataType } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataType, user: string, type: string) => {
    let consult = `Según el significado de las cartas, me puedes proporcionar la interpretación de la tirada de la Cruz Celta para ${data.consultante.name} que necesita saber sobre lo siguiente: ${data.consulta}, con las siguientes cartas: ${cards[0].name} en la posicion central o situación actual, ${cards[1].name} en la influencia, ${cards[2].name} en el subconsciente, ${cards[3].name} en el pasado, ${cards[4].name} en el consciente, ${cards[5].name} en el futuro cercano, ${cards[6].name} en el consultante, ${cards[7].name} en el entorno, ${cards[8].name} en la solución o consejo y ${cards[9].name} en el resultado final. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
